var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',{staticClass:"card-title  mr-3"},[_vm._v(_vm._s(_vm.$t("areaListPage.pageTitle")))])]),_c('CCardBody',{staticClass:"px-5"},[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","size":"sm","items":_vm.tableItems,"fields":_vm.TableFields,"column-filter":"","sorter":"","itemps-per-page-select":"","items-per-page":20,"pagination":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.id))])])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.name))])])}},{key:"pronunciation",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(item.pronunciation))])])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.description))])])}},{key:"operation",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditClicked(item.id)}}},[_c('i',{staticClass:"icon cil-pencil mr-1"}),_vm._v(_vm._s(_vm.$t("areaListPage.detail")))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }