<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title  mr-3">{{ $t("areaListPage.pageTitle") }}</strong>
            <!-- TODO:現在は確認のみ行う画面の為ボタン非表示 -->
            <!-- NOTE:createとeditは同じモーダル使用している為、注意 -->
            <!-- <CButton
              color="info"
              @click="addNewArea()"
            >{{ $t("common.create") }}</CButton> -->
          </CCardHeader>
          <CCardBody class="px-5">
            <CDataTable
              class="mb-0 table-outline"
              hover
              size="sm"
              :items="tableItems"
              :fields="TableFields"
              column-filter
              sorter
              itemps-per-page-select
              :items-per-page="20"
              pagination
            >
              <td
                slot="id"
                slot-scope="{ item }"
              >
                <strong>{{ item.id }}</strong>
              </td>
              <td
                slot="name"
                slot-scope="{ item }"
              >
                <strong>{{ item.name }}</strong>
              </td>
              <td
                slot="pronunciation"
                class="text-left"
                slot-scope="{ item }"
              >
                <strong>{{ item.pronunciation }}</strong>
              </td>
              <td
                slot="description"
                slot-scope="{ item }"
              >
                <strong>{{ item.description }}</strong>
              </td>
              <template #operation="{ item }">
                <td class="py-2 text-center">
                  <CButton
                        class=""
                        color="info"
                        square
                        size="sm"
                        @click="onEditClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i
                        >{{ $t("areaListPage.detail") }}</CButton
                      >
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import i18n from "@/i18n";

const PAGE_URL = `/admin/area`;

export default {
  name: "AreaMaster",
  data() {
    return {
      //#region Flag
      loading: false,
      isDisabled: true,
      //#endregion Flag
      
      tableItems: [],
      order: [],
      response: [],
      errorMessage: "",

      TableFields: [
        {
          key: "id",
          label: `${i18n.t("areaListPage.id")}`,
          _classes: "text-center",
          _style: "width:7%",
        },
        {
          key: "name",
          label: `${i18n.t("areaListPage.name")}`,
          _classes: "text-center",
          _style: "width:14%",
        },
        {
          key: "pronunciation",
          label: `${i18n.t("areaListPage.pronunciation")}`,
          _classes: "text-center",
        },
        {
          key: "description",
          label: `${i18n.t("areaListPage.description")}`,
          _classes: "text-center",
        },
        {
          key: "operation",
          label: "",
          sorter: false,
          filter: false,
          _style: "width:5%",
          _classes: "text-center",
        },
      ],
    };
  },
  methods: {
    /** 編集ボタン押下 */
    onEditClicked(id) {
      this.$router.push({
        path: `${PAGE_URL}/${id}/edit`,
        query: { type: this.selectedType },
      });
    },

    //#region Request

    reqGet() {
      const callback = (a) => {
        this.updateTable(a.areas);
        this.createDisplayOrderIdAscList(a.areas);
        this.loading = false;
      };

      this.reqGetAreaAll(callback);
    },

    
    //#region Method
    /**
     * 一覧表更新
     * @param datas
     */
    updateTable(datas) {
      this.clearTable();
      datas
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((data) => {
          this.tableItems.push({
            id: data.id,
            name: data.name,
            pronunciation: data.pronunciation,
            description: data.description,
            displayOrder: data.displayOrder,
          });
        });
    },

    /** 一覧表をクリア */
    clearTable() {
      this.tableItems = [];
    },

    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(item) {
      this.order = [];
      item.forEach((a) => this.order.push(a.id));
      this.order.sort((a, b) => a - b);
    },

    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },
  beforeMount() {
    this.reqGet();
  },
};
</script>